import React, { useState } from "react"
import { AppContext } from "../../context/ContextProvider"
import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import axios from "axios"

function PresetSelect() {
  const { prompts, selectedPrompt, setSelectedPrompt } =
    React.useContext(AppContext)

  console.log(JSON.stringify(selectedPrompt))
  const [loading, setLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  async function handleSave() {
    // Update Flow
    const request = { ...selectedPrompt, isEmptyTemplate: false };
    if (selectedPrompt?._id !== undefined && selectedPrompt?._id !== null) {
      await updatePrompt(request, selectedPrompt?._id)
    } else {
      await savePrompt(request)
    }
  }


  async function handleDelete() {
    const confirmed = window.confirm(
      "Are you sure you want to delete this prompt?"
    )
    if (!confirmed) return

    try {
      setLoading(true)
      const success = await deleteDocument(selectedPrompt, "prompts")
      if (success) {
        setLoading(false)
        toast({
          title: "Preset deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: "Error Loading preset",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  function handleSelect(e) {
    const newSelectedPrompt = prompts.find(
      prompt => prompt.uuid === e.target.value
    )

    if (newSelectedPrompt) {
      setSelectedPrompt(newSelectedPrompt)
    }
  }

  const savePrompt = async (data) => {
    setLoading(true)
    await axios.post(`${process.env.GATSBY_API_BASE_URL}/prompts`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res: any) => {
        toast({
          title: `Prompt created successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      })
      .catch(error => {
        console.error(error)
        toast({
          title: "Error saving preset",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      })
  }

  const updatePrompt = async (data, id) => {
    setLoading(true)
    await axios.patch(`${process.env.GATSBY_API_BASE_URL}/prompts/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res: any) => {
        toast({
          title: `Prompt updated successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      })
      .catch(error => {
        console.error(error)
        toast({
          title: "Error updating preset",
          description: "Something went wrong",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      })
  }

  return selectedPrompt ? (
    <>
      <HStack mb={4}>
        <Select
          maxW="300px"
          size="md"
          rounded="sm"
          borderColor="gray.100"
          value={selectedPrompt.uuid}
          onChange={handleSelect}
          placeholder="Select a preset"
        >
          {prompts.map(prompt => (
            <option key={prompt.uuid} value={prompt.uuid}>
              {prompt.name}
            </option>
          ))}
        </Select>

        <Button
          bg="red.full"
          size="md"
          rounded="sm"
          color="white"
          onClick={onOpen}
          isLoading={loading}
          isDisabled={loading}
        >
          Save
        </Button>
        <Button
          bg="gray.50"
          size="md"
          rounded="sm"
          color="dark.full"
          py={1}
          onClick={handleDelete}
          isLoading={loading}
          isDisabled={loading || selectedPrompt.isEmptyTemplate}
        >
          Delete
        </Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Prompt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="12px">Name</Text>
            <Input
              name="name"
              id="name"
              variant="outline"
              size="md"
              value={selectedPrompt?.name}
              onChange={e =>
                setSelectedPrompt({
                  ...selectedPrompt,
                  name: e.target.value,
                })
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button bg="red.full" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg="teal.full"
              mr={3}
              onClick={handleSave}
              isLoading={loading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null
}

export default PresetSelect
